// import original module declarations
import "styled-components"

// and extend them!
declare module "styled-components" {
	export interface DefaultTheme {
		primary: string
		primaryLight: string
		primaryDark: string
		backgroundPrimary: string
		backgroundSecondary: string
		fontPrimary: string
		fontSecondary: string
		btnPrimary: string
		btnPrimaryLight: string
		btnPrimaryDark: string
		inputBackgroundColor: string
		inputColor: string
		labelColor: string
		shrinkLabelColor: string
		white: string
		red: string
		black: string
		green: string
		gray: string
		sm: string
		m: string
	}
}

class CustomTheme {
	primary: string
	primaryLight: string
	primaryDark: string
	backgroundPrimary: string
	backgroundSecondary: string
	fontPrimary: string
	fontSecondary: string
	btnPrimary: string
	btnPrimaryLight: string
	btnPrimaryDark: string
	inputBackgroundColor: string
	inputColor: string
	labelColor: string
	shrinkLabelColor: string
	white: string
	red: string
	black: string
	green: string
	gray: string
	sm: string
	m: string

	constructor(
		primary: string,
		primaryLight: string,
		primaryDark: string,
		backgroundPrimary: string,
		backgroundSecondary: string,
		fontPrimary: string,
		fontSecondary: string,
		btnPrimary: string,
		btnPrimaryLight: string,
		btnPrimaryDark: string,
		inputBackgroundColor: string,
		inputColor: string,
		labelColor: string,
		shrinkLabelColor: string,
		white: string,
		red: string,
		black: string,
		green: string,
		gray: string,
		sm: string,
		m: string
	) {
		this.primary = primary
		this.primaryLight = primaryLight
		this.primaryDark = primaryDark
		this.backgroundPrimary = backgroundPrimary
		this.backgroundSecondary = backgroundSecondary
		this.fontPrimary = fontPrimary
		this.fontSecondary = fontSecondary
		this.btnPrimary = btnPrimary
		this.btnPrimaryLight = btnPrimaryLight
		this.btnPrimaryDark = btnPrimaryDark
		this.inputBackgroundColor = inputBackgroundColor
		this.inputColor = inputColor
		this.labelColor = labelColor
		this.shrinkLabelColor = shrinkLabelColor
		this.white = white
		this.red = red
		this.black = black
		this.green = green
		this.gray = gray
		this.sm = sm
		this.m = m
	}
}

export const lightTheme = new CustomTheme(
	"hsl(0.6122448979591836, 78.4%, 50.98039215686274%)", // primary
	"hsl(0.6122448979591836, 78.4%, 60.98039215686274%)", // primaryLight
	"hsl(0.6122448979591836, 78.4%, 40.98039215686274%)", // primaryDark
	"#fff", // backgroundPrimary
	"#fcfcfc", // backgroundSecondary
	"#000", // fontPrimary
	"#363636", // fontSecondary
	"hsl(0.6122448979591836, 78.4%, 50.98039215686274%)", // btnPrimary
	"hsl(0.6122448979591836, 78.4%, 60.98039215686274%)", // btnPrimaryLight
	"hsl(0.6122448979591836, 78.4%, 40.98039215686274%)", // btnPrimaryDark
	"#EBEBEB", // inputBackgroundColor
	"#000", // inputColor
	"#000", // labelColor
	"#000", // shrinkLabelColor
	"#fff", // white
	"#ED4C67", // red
	"#000", // black
	"#009432", // green
	"rgba(0,0,0,0.6)", // gray
	"40.063em", // sm
	"64.063em" // m
)

export const darkTheme = new CustomTheme(
	"hsl(0.6122448979591836, 78.4%, 50.98039215686274%)", // primary
	"hsl(0.6122448979591836, 78.4%, 60.98039215686274%)", // primaryLight
	"hsl(0.6122448979591836, 78.4%, 40.98039215686274%)", // primaryDark
	"#fff", // backgroundPrimary
	"#fcfcfc", // backgroundSecondary
	"#000", // fontPrimary
	"#363636", // fontSecondary
	"hsl(0.6122448979591836, 78.4%, 50.98039215686274%)", // btnPrimary
	"hsl(0.6122448979591836, 78.4%, 60.98039215686274%)", // btnPrimaryLight
	"hsl(0.6122448979591836, 78.4%, 40.98039215686274%)", // btnPrimaryDark
	"#EBEBEB", // inputBackgroundColor
	"#000", // inputColor
	"#000", // labelColor
	"#000", // shrinkLabelColor
	"#fff", // white
	"#ED4C67", // red
	"#000", // black
	"#009432", // green
	"rgba(0,0,0,0.6)", // gray
	"40.063em", // sm
	"64.063em" // m
)
