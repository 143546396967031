import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client"

import { StoreProvider } from "easy-peasy"
import NotificationsProvider from "./components/notifications/notifications.context"
import { store } from "./store/store"

import { setContext } from "@apollo/client/link/context"

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_URI,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("accessToken")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "x-forwarded-key": process.env.REACT_APP_UPLOAD_API_XF ?? "",
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
})

if (process.env.NODE_ENV === "production") {
  console.log("Sentry is enabled")
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <StoreProvider store={store}>
        <NotificationsProvider>
          <App />
        </NotificationsProvider>
      </StoreProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
